var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"rounded-0",style:(_vm.styles),attrs:{"headers":_vm.headers,"items":_vm.receipts,"loading":_vm.apiCallLoading,"items-per-page":10,"no-data-text":_vm.$t('NoData'),"loading-text":_vm.$t('LoadingData'),"footer-props":{
    itemsPerPageAllText: _vm.$t('All'),
    itemsPerPageText: _vm.$t('RowsPerPage'),
    pageText: '{0}-{1} ' + _vm.$t('Of') + ' {2}',
    showCurrentPage: true,
    showFirstLastPage: true,
  }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$tc('Receipt', 2))+" ")])],1)]},proxy:true},{key:"item.reservation",fn:function(ref){
  var item = ref.item;
return [_c('div',[(item.reservation)?_c('div',[_vm._v(_vm._s(item.reservation.id)+" / "+_vm._s(item.reservation.from)+" / "+_vm._s(item.reservation.total_cost))]):_c('div',[_vm._v("N/A")])])]}},{key:"item.payment_type",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPaymentType(item.payment_type))+" ")]}},{key:"item.type",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getType(item.type))+" ")]}},{key:"item.notes",fn:function(ref){
  var item = ref.item;
return [_c('div',[_c('v-dialog',{attrs:{"max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({attrs:{"href":"#"}},'a',attrs,false),on),[_vm._v(_vm._s(_vm.$t("Details")))])]}},{key:"default",fn:function(dialog){return [_c('notes-card',{attrs:{"item":item},scopedSlots:_vm._u([{key:"card-actions",fn:function(){return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v(" "+_vm._s(_vm.$t("Close"))+" ")])]},proxy:true}],null,true)})]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }