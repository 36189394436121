import axios from 'axios';

async function index(data = {}) {
    return await new Promise((resolve, reject) => {
        axios
            .get(`receipts`, { params: data })
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            })
    })
}

export {
    index,
}