<template>
  <div class="receipts-page">
    <v-container fluid fill-height class="pa-0">
      <v-row justify="center" align="center">
        <v-col cols="12" class="receipts-data-table">
          <receipts-data-table :styles="{height: '80vh', overflowY: 'auto'}" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ReceiptsDataTable from "./DataTable";
export default {
  name: "AccountReceipts",
  components: {
    ReceiptsDataTable,
  },
};
</script>

<style lang="scss" scoped>
.receipts-page {
  min-height: 80vh;
}
</style>
