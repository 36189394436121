<template>
  <v-card v-bind="cardProps">
    <v-toolbar v-bind="cardToolbarProps">
      {{ cardTitle }}
    </v-toolbar>
    <v-card-text v-bind="cardTextProps">
      <v-row no-gutters>
        <v-col cols="12">
          <p>
            {{ item.notes }}
          </p>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-bind="cardActionsProps">
      <slot name="card-actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from "vue";
import i18n from "../../../../i18n/i18n";

export default {
  name: "ReceiptNotes",
  props: {
    item: {
      type: Object,
      default: (_) => {
        return {
            notes: null
        };
      },
    },
    cardTitle: {
      type: String,
      default: i18n.t("Notes"),
    },
    cardProps: {
      type: Object,
      default: (_) => {
        return {};
      },
    },
    cardTextProps: {
      type: Object,
      default: (_) => {
        return {
          class: "pa-5 text-h6",
        };
      },
    },
    cardToolbarProps: {
      type: Object,
      default: (_) => {
        return {
          color: "dark",
          dark: true,
          class: "text-h6",
        };
      },
    },
    cardActionsProps: {
      type: Object,
      default: (_) => {
        return {
          class: "justify-end",
        };
      },
    },
  },
  methods: {
  },
};
</script>