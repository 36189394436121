<template>
  <v-data-table
    :style="styles"
    :headers="headers"
    :items="receipts"
    :loading="apiCallLoading"
    :items-per-page="10"
    class="rounded-0"
    :no-data-text="$t('NoData')"
    :loading-text="$t('LoadingData')"
    :footer-props="{
      itemsPerPageAllText: $t('All'),
      itemsPerPageText: $t('RowsPerPage'),
      pageText: '{0}-{1} ' + $t('Of') + ' {2}',
      showCurrentPage: true,
      showFirstLastPage: true,
    }"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title class="text-h5 font-weight-bold"> {{$tc('Receipt', 2)}} </v-toolbar-title>
      </v-toolbar>
    </template>
    <template v-slot:item.reservation="{ item }">
      <div>
        <div v-if="item.reservation">{{ item.reservation.id }} / {{ item.reservation.from }} / {{ item.reservation.total_cost }}</div>
        <div v-else>N/A</div>
      </div>
    </template>
    <template v-slot:item.payment_type="{item}">
      {{ getPaymentType(item.payment_type) }}
    </template>
    <template v-slot:item.type="{item}">
      {{ getType(item.type) }}
    </template>
    <template v-slot:item.notes="{ item }">
      <div>
        <v-dialog max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <a href="#" v-bind="attrs" v-on="on">{{ $t("Details") }}</a>
          </template>
          <template v-slot:default="dialog">
            <notes-card :item="item">
              <template v-slot:card-actions>
                <v-btn text @click="dialog.value = false">
                  {{ $t("Close") }}
                </v-btn>
              </template>
            </notes-card>
          </template>
        </v-dialog>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import Vue            from "vue";
import { mapGetters } from "vuex";
import { index }      from "../../../api/receipt";
import NotesCard      from "./components/NotesCard";

export default {
  name: "ReceiptsDataTable",
  components: {
    NotesCard
  },
  props: {
    styles: {
      type: Object,
      default: (_) => {
        return {};
      },
    },
  },
  data() {
    return {
      headers: [
        { text: this.$t("ReceiptID"), value: "id" },
        { text: this.$t("Date"), value: "date" },
        { text: this.$t("PaymentType"), value: "payment_type" },
        { text: this.$t('Type'), value: 'type' },
        { text: this.$t("ReservationNumber"), value: "reservation" },
        { text: this.$t("Amount"), value: "amount" },
        { text: this.$t("Notes"), value: "notes" },
      ],
      apiCallLoading: false,
      receipts: [],
      pagination: {
        limit: -1,
        page: 1,
      },
    };
  },
  computed: {
    ...mapGetters({
      locale:       "locale",
      paymentTypes: "getPaymentTypes",
      receiptTypes: "getReceiptTypes"
    }),
  },
  methods: {
    getPaymentType(type) {
      let ptype = this.paymentTypes.filter(t => t.value == type)[0];
      if (ptype) return ptype.text;
      return type;
    },
    getType(type) {
      let rtype = this.receiptTypes.filter(t => t.value == type)[0];
      if (rtype) return rtype.text;
      return type;
    },
    fetchReceipts() {
      this.apiCallLoading = true;

      let data = {
        ...this.pagination,
      };

      index(data)
        .then((resp) => {
          this.receipts = resp.data.data;
        })
        .finally(() => {
          setTimeout(() => {
            this.apiCallLoading = false;
          }, 500);
        });
    },
    convertReservationCost(item) {
      let companyUSDCurrency = item.car.company.currencies.filter(
        (currency) => currency.iso == "USD"
      )[0];

      let itemCurrencyID = companyUSDCurrency.id;
      let itemExchangeRate = companyUSDCurrency.exchange_rate.rate;

      if (item.currency) {
        itemCurrencyID = item.currency.id;
        itemExchangeRate = item.exchange_rate;
      }

      const cost = Vue.currencyHelper.convert(
        item.amount_after_tax,
        itemExchangeRate,
        itemCurrencyID,
        companyUSDCurrency.exchange_rate.rate,
        companyUSDCurrency.id
      );

      return cost.toFixed(2) + " $";
    },
  },
  mounted() {
    this.fetchReceipts();
  },
};
</script>

<style scoped>

.theme--light.v-data-table {

  background-color: #EEF0EB
}
.v-data-footer {
  background-color: #EEF0EB;
}

</style>